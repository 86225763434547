
li img{
    height: 100vh;
    object-fit: cover;
}

.carousel .slide .legend {
    font-size: 70px;
    background: none;
    font-weight: bold;
    bottom: 30%;
    opacity: 1;
}
@media (max-width: 320px) {
    .carousel .slide .legend {
        font-size: 25px !important;
        bottom: 20% !important;
    }
}

@media (min-width: 321px) and (max-width:560px)  {
    .carousel .slide .legend {
        font-size: 35px !important;
        bottom: 20% !important;
    }
}
@media (min-width:560px) and (max-width:569px)  {
    .carousel .slide .legend {
        font-size: 30px !important;
        bottom: 10% !important;
    }
}
@media (max-width: 640px) {
    li img{
        height: 51vh;
    }
    .carousel .slide .legend {
        font-size: 30px;
    }
}
@media (min-width: 641px) and (max-width: 800px) {
    .carousel .slide .legend {
        font-size: 50px !important;
        bottom: 25%;
    }

}
@media (max-width: 812px) {
    .carousel .slide .legend {
        font-size: 40px;
    }

}